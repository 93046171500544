import React from 'react'

const About = () => {
    return (
        // Component
        <div id="about">
            
            { /* Container */ }
            <div className="mt-2 pt-10 mb-8 container">

                { /* Flexbox */ }
                <div className="flex justify-between">

                    { /* Paragraph text */ }
                    <div className="w-full lg:w-8/12">
                        <h2 className="text-3xl md:text-4 lg:text-5xl text-left text-ntps-blue mb-6">
                            Over ons
                        </h2>
                        <p className="text-lg text-left text-ntps-grey mb-6 lg:mr-12">
                            Heeft u hulp nodig met de software van een Arduino project? 
                            Dan bent u bij ons op de juiste plek. Wij hebben al veel gewerkt met
                            Arduino, en kunnen bijna alles maken (het moet dan wel mogelijk zijn 
                            met de hardware van uw project).
                            <br /><br />
                            In samenwerking met Ben's electronics, kunnen wij nu ook (als u bij 
                            hun hardware bestelt) de software alvast op de hardware installeren
                            voor dat het verzonden wordt.
                            <br /><br />
                            Heeft u vragen of interesse? Dan kunt u een mail sturen naar
                            <a className="text-ntps-primary" href="mailto: info@ntps.nl"> info@ntps.nl </a> 
                            of het contact formulier hieronder invullen.
                        </p>
                    </div>

                    <div className="hidden md:block mt-20 w-3/12 h-3/12">
                        <img src="./arduino.png" alt="" />
                    </div>
                </div>

                <div className="md:hidden mt-12 w-full flex items-center justify-center">
                    <img className="w-6/12 h-6/12" src="./arduino.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default About
