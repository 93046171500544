import React from 'react';

const Input = ({ placeholder, width, id }) => {
	return (
		<>
			<input
				type="text"
				id={id}
				placeholder={placeholder}
				className={`block mb-2 ${width} bg-white p-2 rounded-lg border-2 border-white shadow-md focus:outline-none focus:border-indigo-500`}
			/>
		</>
	);
};

export default Input;
