import React from 'react'

const Footer = () => {
    return (
        <div className="bg-ntps-primary w-full h-12 flex justify-center pt-4 pb-9">
            <small className="text-white">Copyright © { new Date().getFullYear() } ntps. All Rights Reserved.</small>
        </div>
    )
}

export default Footer
