import React from 'react';

const Navbar = () => {
	return (
		<div id="top" className="shadow-md">
			<nav className="container flex items-center pb-3 mt-4">
				<a className="flex py-1 cursor-pointer" href="#top">
					<img className="w-10 h-10" src="./logo.png" alt="Logo" />
					<h1 className="py-1.5 px-4 font-light text-lg">ntps</h1>
				</a>
				<ul className="hidden sm:flex flex-1 justify-end items-center uppercase text-xs">
					<li className="cursor-pointer mr-14">
						<a href="#about">Over ons</a>
					</li>
					<li className="cursor-pointer mr-14">
						<a href="#contact">Contact</a>
					</li>
					<li className="cursor-pointer">
						<a href="#projects">Projecten</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Navbar;
