import React from 'react'
import Input from './Input'
import '../index.css'

const Contact = () => {

    function submitContact()
    {
        const naam = document.getElementById("naam");
        const email = document.getElementById("email");
        const telefoon = document.getElementById("telefoon");
        const omschrijving = document.getElementById("omschrijving");

        var data = {
            naam: naam.value,
            email: email.value,
            telefoon: telefoon.value,
            omschrijving: omschrijving.value
        };


        fetch("contact/submit.php", {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((response) => {response.text()})
        .then((result) => {
            console.log(result);

            const btn = document.getElementById('submitbtn');

            btn.classList.remove("bg-ntps-primary");
            btn.classList.remove("border-ntps-primary");

            btn.classList.add("bg-green-400");
            btn.classList.add("border-green-400");

            btn.innerHTML = "Verstuurd!";
        });
    }

    return (
        // Component
        <div id="contact" className="bg-ntps-white mt-6 pb-12">
            
            { /* Container */ }
            <div className="mt-6 pt-10 mb-6 container">

                { /* Flexbox */ }
                <div className="mt-6 flex justify-between">

                    { /* Paragraph text */ }
                    <div className="w-full mb-4">
                        <h2 className="text-3xl md:text-4 lg:text-5xl text-left text-ntps-blue mb-8">
                            Contact
                        </h2>

                        <div className="flex flex-col md:flex-row gap-6">
                            <Input id="naam" placeholder="Volledige naam" width="w-full md:w-4/12" />
                            <Input id="email" placeholder="Email" width="w-full md:w-4/12" />
                            <Input id="telefoon" placeholder="Telefoon" width="w-full md:w-4/12" />
                        </div>

                        <textarea
                            id="omschrijving"
                            type="text"
                            placeholder="Omschrijving project"
                            className={`block align-text-top w-full h-96 mt-4 mb-2 bg-white p-2 rounded-lg border-2 border-white shadow-md focus:outline-none focus:border-indigo-500`}
                        ></textarea>

                        <button id='submitbtn' onClick={submitContact} className="w-full h-12 rounded-md mt-4 bg-ntps-primary text-white hover:bg-white hover:text-ntps-primary border-2 border-ntps-primary transition duration-300">
                            Verstuur
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
